import React from 'react';
import { IMAGE, LINK } from '@common/constants';
import styled from 'styled-components';
import { LinkButton } from '@common/components/button';

export default function Shop() {
  return (
    <>
      <Logo>
        <img src={IMAGE.LOGO_LARGE_WHITE} alt="free.la" />
      </Logo>

      <Tel>
        <a href="tel:+81427076116">
          <img src={IMAGE.TEL} alt="電話番号" />
        </a>
      </Tel>

      <LinkButton href="#contact">Webお問い合わせはこちら</LinkButton>

      <ShopFrontImage>
        <img src={IMAGE.SHOP_FRONT} alt="free.la" />
      </ShopFrontImage>

      <center>
        <p>
          <b>&#x3010;肉とクラフトビール&#x3011;</b>
        </p>
        <p>
          <b>鉄板焼きステーキ肉&amp;アメリカンダイニング</b>
        </p>
        <p>
          <b>&#9734;町田駅徒歩3分&#9733;隠れ家空間</b>
        </p>
      </center>

      <ShopDescription>
        <p>
          <b>
            &#9733;国産地鶏のバッファローウィングなどアメリカテイストの肉料理が満載♪
          </b>
        </p>
        <p>ブランド豚やアメリカテイストの肉料理が満載♪</p>
      </ShopDescription>
      <ShopDescription>
        <p>
          <b>&#9733;ビールにあうお食事も充実</b>
        </p>
        <p>
          「フィッシュ＆チップス」や珍しい「ブラジル式極太ソーセージ」など幅広くこだわりの料理が楽しめます！
        </p>
      </ShopDescription>
      <ShopDescription>
        <p>
          <b>&#9733;季節限定クラフトビールを生樽で常時12種類!</b>
        </p>
        <p>海外の珍しいビールも30種類以上[缶や瓶]でご用意</p>
        <p>
          お得なコース&amp;クラフトビール飲み放題プランも2名様からご利用いただけます
        </p>
      </ShopDescription>

      <Info>
        <dt>店名</dt>
        <dd>肉とクラフトビール free.la町田店</dd>

        <dt>住所</dt>
        <dd>&#12306;194-0013</dd>
        <dd>東京都町田市原町田4-10-18 MSM町田&#8546;2階奥</dd>

        <dt>アクセス</dt>
        <dd>JR横浜線「町田駅」ターミナル口より徒歩約3分</dd>
        <dd>小田急小田原線「町田駅」東口より徒歩約8分</dd>
        <dd>
          仲見世商店街を抜けて30秒ファミリーマートさんを抜けて牛角さんの隣の2階になります!
        </dd>

        <dt>営業時間</dt>
        <dd>&#x3010;月〜金&#x3011;&nbsp;</dd>
        <dd>17:00〜24:00</dd>
        <dd>&#x3010;土&#x3011;&nbsp;</dd>
        <dd>13:00〜24:00</dd>
        <dd>&#x3010;日・祝日&#x3011;&nbsp;</dd>
        <dd>13:00〜22:00</dd>
        <dd>&#x3010;ラストオーダー&#x3011;&nbsp;</dd>
        <dd>Food 1時間前 / Drink 30分前</dd>

        <dt>定休日</dt>
        <dd>年末年始</dd>

        <dt>予算</dt>
        <dd>&#x00A5;2,000&nbsp;〜&nbsp;&#x00A5;3,000</dd>
        <dd>サービス料なし, チャージ料なし, お通し代なし</dd>

        <dt>駐車場</dt>
        <dd>無し（近隣にコインパーキング多数あり）</dd>

        <dt>総席数</dt>
        <dd>着席時34人, 立食時40人</dd>
        <dd>個室なし</dd>

        <dt>貸切</dt>
        <dd>可（20人〜50人）&#8251;詳しくはお問い合わせください</dd>

        <dt>禁煙・喫煙</dt>
        <dd>全席禁煙（電子タバコ専用喫煙ルームあり）</dd>

        <dt>お支払い方法</dt>
        <dd>クレジットカード（VISA, Master, AMEX, JCB, Diners）</dd>
        <dd>電子マネー（交通系IC, 楽天Edy, iD）</dd>
        <dd>QRコード決済（PayPay, d払い）</dd>

        <dt>空間・設備</dt>
        <dd>
          オシャレな空間, 落ち着いた空間, 席が広い, カウンター席あり,
          スポーツ観戦可, 立ち飲みができる, プロジェクターあり
        </dd>
      </Info>
      <GoogleMap
        src={LINK.GOOGLE_MAP}
        width="600"
        height="450"
        allowFullScreen
        loading="lazy"
        referrerPolicy="no-referrer-when-downgrade"
      />
    </>
  );
}

const Logo = styled.figure`
  padding: 1rem;

  > img {
    width: 100%;
  }
`;
const ShopFrontImage = styled.figure`
  margin-top: 1rem;

  > img {
    width: 100%;
  }
`;
const ShopDescription = styled.div`
  margin-top: 1rem;
`;
const Tel = styled.div`
  text-align: center;
  margin-bottom: 1rem;

  > a img {
    width: 56%;
  }
`;
const Info = styled.dl`
  border: dashed 1px #000;
  padding: 0 0.75rem 0.75rem 0.75rem;
  margin-top: 1rem;

  > dt {
    margin-top: 1rem;
    margin-bottom: 0.5rem;

    &::before {
      content: '▶︎';
      margin-right: 0.25rem;
    }
  }

  > dd {
    padding-left: 1rem;
  }
`;
const GoogleMap = styled.iframe`
  border: 0;
  width: 100%;
  margin-top: 1rem;
`;
