import { CircleImage } from '@common/components/circle_images';

export const COURSES: CircleImage[] = [
  {
    name: '◆新春お得コース◆2時間飲み放題+絶品料理全6品/新春でお得に楽しめるプランが期間限定で登場',
    price: 4000,
    path: 'https://lh3.googleusercontent.com/pw/ABLVV87SE9Ggn7I83fWapULIlTgApfgBvS5rCAEj9tneiXt58r0VT4B3B7yjI0j5aJ2rbHZwTLtnPNV6O9gw52RQHsN1qw_cIaJdyPYylOrspywXFO24zaJlKuzOqceIFUSDtfwtBZbspemUHNtobJRYYV5O=w1280-h1280-s-no-gm?authuser=0',
    descriptions: [
      '利用可能人数: 2～40名様',
      '利用可能曜日: 月～日・祝日・祝前日',
      '利用可能時間: 13:00～23:30',
      '滞在可能時間: 2時間',
      '',
      'コース内容',
      '☆1月~2月限定コース☆',
      '',
      '新春でお得に楽しめるフード6品付きフリーラの飲み放題（2時間）プランです！お気軽にご利用ください。デートや友人との新年会、飲み会にもオススメ！！',
      '',
      '※飲み放題ドリンクは生ビール・カクテル・ワイン&ソフトドリンクも多数ご用意♪',
      '※フードの追加オーダーも可能です！',
      '',
      'お料理内容',
      '・前菜盛り合わせ3種',
      '・グリーンサラダ',
      '・名物フィッシュ&チップス',
      '・極太生ソーセージのグリル',
      '',
      '◆2時間飲み放題付き(L.O 30分前)',
      '・生ビール1種類(バドワイザー)飲み放題',
      '・カクテル 40種類',
      '・グラス赤・白ワイン',
      '・ソフトドリンク 5種類',
      '計50種類が飲み放題！',
    ],
  },
  {
    name: '【プレミアムコース】★当店1番人気のコース内容★クラフトビール好きにも大好評♪クラフト生ビール全12種類+50種類ドリンク飲み放題(3時間)付き&名物料理や黒毛和牛ステーキなど全11品のお料理',
    price: 7000,
    path: 'https://lh3.googleusercontent.com/pw/ABLVV84QK2YVia4xk_g6rjNnrHN6tM5ZSHFiYXCU5nLuU3c3FusuZhhvs5b5LPomkCJVb4t_gOTFYT3LWmWiQrkFNi1yVZ8OoBvIvNDLdVZkILOAJHXjXVQoXAc-8rDfk04jkDckhTkXL2qw9EafAUwrmMpb=w1536-h1536-s-no-gm?authuser=0',
    descriptions: [
      '利用可能人数: 2～18名様',
      '利用可能曜日: 月～日・祝日・祝前日',
      '利用可能時間: 13:00～23:30',
      '滞在可能時間: 3時間',
      '',
      'コース内容',
      'フリーラの美味しい料理とクラフトビール含む全生ビール12種類をゆっくり堪能できるコースです！',
      'コース内容（全11品）＋3時間飲み放題(L.O30分前)',
      '',
      '【フードメニュー】',
      '・前菜盛り合わせ3種類',
      '  (例)・コーンとチーズの冷製オムレツ',
      '      ・イカと旬野菜のジェノヴェーゼ',
      '      ・鶏レバーのパテ ~バケットのせ~',
      '・生ハムサラダ ~バルサミコドレッシング~',
      '・当店自慢のフィッシュ&チップス',
      '・ガーリックトースト',
      '・豪華肉盛りプレート(お肉5種類盛り)',
      '  (例)・A5ランク黒毛和牛もも肉のステーキ',
      '      ・名物鳥取県産大山地鶏のバッファローウィング',
      '      ・漬け込み牛ハラミのステーキ',
      '      ・極太生ソーセージ(リングイッサ)のグリル',
      '      ・燻製ベーコンのソテー',
      '',
      '【ドリンクメニュー】',
      '3時間飲み放題(L.O30分前)',
      '・クラフトビール含む全12種類飲み放題',
      '・カクテル 50種類',
      '・グラス赤・白ワイン',
      '・ソフトドリンク 5種類',
      '計60種類が飲み放題!',
    ],
  },
  {
    name: '【スタンダードコース】クラフトビール全12種類+50種類ドリンク飲み放題(2.5時間)付き&お料理全10品《カジュアルに楽しむならコレ！》デートにも◎ 2名様からOK！！',
    price: 6000,
    path: 'https://lh3.googleusercontent.com/pw/ABLVV87GkpeAt_pPbofc3luwIikqtBzZcnmn7lhL9gZFzvZcT0tdhe9eMqTQrT4n_YqLHb1iL3M93p0vr3oL37RovmXCywAC8Vgj-T7mbEFMZgHKyx3_kTW_W7K86TQL-GtdpqFq5UZt4ZILRxbfEbWWL7qp=w1536-h1536-s-no-gm?authuser=0',
    descriptions: [
      '利用可能人数: 2～32名様',
      '利用可能曜日: 月～日・祝日・祝前日',
      '利用可能時間: 13:00～23:30',
      '滞在可能時間: 2時間30分',
      '',
      'コース内容',
      'お食事は当店自慢の肉料理の4種盛りや大人気のフィッシュ＆チップスを含めた',
      'お料理全10品とクラフト生ビール全12種類の飲み放題2.5時間が楽しめます',
      'コース内容（全10品）＋2.5時間飲み放題(L.O30分前)',
      '',
      '【フードメニュー】',
      '・前菜盛り合わせ3種類',
      '  (例)・コーンとチーズの冷製オムレツ',
      '      ・鶏と旬野菜のピリ辛マリネ',
      '      ・鶏レバーのパテ',
      '・彩り野菜のコブサラダ',
      '・当店自慢のフィッシュ&チップス',
      '・ガーリックトースト',
      '・肉盛りプレート(お肉4種類)',
      '  (例)・牛ハラミのステーキ',
      '      ・鳥取県産大山地鶏のバッファローウィング',
      '      ・燻製厚切りベーコンのソテー',
      '      ・極太生ソーセージ(リングイッサ)のグリル',
      '',
      '【ドリンクメニュー】',
      '《クラフトビール含む全12種類飲み放題》',
      '・カクテル 50種類+グラス赤・白ワイン+ソフトドリンク5種類',
      '☆計60種類が飲み放題!☆',
    ],
  },
  {
    name: '【レギュラーコース】クラフトビール全12種類+50種類ドリンク飲み放題(2時間)付き&お料理全8品☆フリーラを気軽に楽しめるプラン♪飲み会利用歓迎☆直接来店OK☆',
    price: 5000,
    path: 'https://lh3.googleusercontent.com/pw/ABLVV87SE9Ggn7I83fWapULIlTgApfgBvS5rCAEj9tneiXt58r0VT4B3B7yjI0j5aJ2rbHZwTLtnPNV6O9gw52RQHsN1qw_cIaJdyPYylOrspywXFO24zaJlKuzOqceIFUSDtfwtBZbspemUHNtobJRYYV5O=w1280-h1280-s-no-gm?authuser=0',
    descriptions: [
      '利用可能人数: 2～40名様',
      '利用可能曜日: 月～日・祝日・祝前日',
      '利用可能時間: 13:00～23:30',
      '滞在可能時間: 2時間',
      '',
      'コース内容',
      'お手軽に楽しめるフリーラのフード8品付きの飲み放題プランです！',
      '会社や友人との飲み会☆忘年会や新年会などで幅広くご利用ください',
      'コース内容（全８品）・2時間飲み放題付き(L.O30分前)',
      'クラフトビール含む全12種類飲み放題！＋カクテル50種類＋グラス赤・白ワイン＋ソフトドリンク5種類【計60種類が飲み放題！】',
      'お料理【・前菜盛り合わせ2種・フレッシュ野菜のシーザーサラダ・名物フィッシュ＆チップス・ガーリックトースト・お肉3種盛り合わせ（牛ハラミのステーキ／鳥取県産大山地鶏のバッファローウィング／ブラジル式極太生ソーセージのグリル】',
      '',
      '【フードメニュー】',
      '・前菜盛り合わせ2種類',
      '・フレッシュ野菜のシーザーサラダ',
      '・名物フィッシュ&チップス',
      '・ガーリックトースト',
      '・お肉3種類の盛り合わせプレート',
      '    ・牛ハラミのステーキ',
      '    ・鳥取県産大山地鶏のバッファローウィング',
      '    ・ブラジル式極太生ソーセージのグリル',
      '',
      '【ドリンクメニュー】',
      '◆2時間飲み放題付き(L.O30分前)◆',
      'クラフトビール含む全12種類飲み放題!',
      'カクテル50種類',
      'グラス赤・白ワイン',
      'ソフトドリンク5種類',
      '計60種類が飲み放題!',
    ],
  },
];
