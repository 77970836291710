import React from 'react';
import { IMAGE } from '@common/constants';
import { HeaderLarge, HeaderMiddle } from '@common/components/text';
import styled from 'styled-components';
import { DottedLayout } from '@common/components/layout';

export default function Recruit() {
  return (
    <>
      <HeaderLarge $my={1}>Recruit</HeaderLarge>

      <HeaderMiddle>採用情報</HeaderMiddle>
      <img src={IMAGE.RECRUIT} width="100%" alt="採用情報"></img>
      <TextBold>
        私たちは、常に「お客様志向」のもと行動するチームです。
      </TextBold>
      <Text>free.laは、「お客様志向」がいつも合言葉のお店です。</Text>
      <Text>全ての社員がいつもお客様のことを思っています。</Text>
      <Text>技術や経験はあまり重視しません。</Text>
      <Text>
        あなたの純粋なおもてなしの心を、あなたらしい表現でお客様に伝えて下さい。
      </Text>
      <Text>あなたのご応募を心よりお待ちしております！</Text>

      <HeaderMiddle>募集職種</HeaderMiddle>
      <TextBold>これからも、ぞくぞく出店計画中！！！</TextBold>
      <Text>興味がある方・働きたい方は、お気軽に是非ご連絡ください！！</Text>
      <DottedLayout
        title={'正社員'}
        contents={[
          { subtitle: '給与', contents: ['月給25万円〜'] },
          {
            subtitle: '待遇',
            contents: [
              '交通費支給',
              '美味しい賄い付き',
              '社会保険完備',
              '研修3ヶ月あり',
            ],
          },
        ]}
      ></DottedLayout>
      <DottedLayout
        title={'アルバイト'}
        contents={[
          {
            subtitle: '給与',
            contents: ['時給 1,200円〜1,800円', '※研修3ヶ月は時給1,170円'],
          },
          {
            subtitle: '待遇',
            contents: [
              '交通費支給',
              '美味しい賄い付き',
              '賄い制度有り',
              '労災保険完備',
            ],
          },
        ]}
      ></DottedLayout>
    </>
  );
}

const Text = styled.p`
  font-size: 0.75rem;
  margin: 0.5rem 0;
`;

const TextBold = styled.p`
  font-weight: bold;
  font-size: 1rem;
  margin: 1rem 0;
`;
